import React from 'react';
import Layout from '../layout';
import { Wrapper } from '../views/ui';

const NotFoundPage = ({ pageContext: { metaData = {}, menus = [] } }) => (
  <Layout SEOTitle="404: Not found" metaData={metaData} menus={menus}>
    <Wrapper
      mt={5}
      minHeight="calc(100vh - 357px)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      flexDirection="column"
    >
      <h1>404 | NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness...</p>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
